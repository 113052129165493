import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'gatsby'
import logoLight from "../images/logo-abt.png";
import logoDark from "../images/logo-abt-dark.png";
import fbWhite from "../images/facebook-logo.svg";
import instaWhite from "../images/instagram-logo.svg";


import { Sticky } from 'react-sticky';


import "./MainMenu.css";


function MainMenu(props) {
    const [menuState, setMenuState] = useState(false);
    let menu = props.headerMenu ? props.headerMenu.menuAcf.menu : [];

    useEffect(() => {
        let menu = document.getElementsByClassName("mobile-menu");
        if (menu[0].style.top === "-375px") {
            menu[0].style.top = "64px"
        } else {
            menu[0].style.top = "-375px"
        }
    }, [menuState])

    function toggleMenu() {
        setMenuState(!menuState)
    }

    function expandMenu(e) {
        e.preventDefault();
    }



    function createSubMenu(subs, parentLabel, distanceFromTop) {
        if (parentLabel !== "Υπηρεσίες" && parentLabel !== "Services" && parentLabel !== "Εξετάσεις" && parentLabel !== "Examinations") {
            return (
                <ul className={"top80"}>
                    {subs.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.url && subLink.url.includes("https://")
                                ?
                                <a href={`/${subLink.url}/`} className="menu-g-link">
                                    {subLink.label}
                                </a>
                                : (
                                    subLink.url
                                        ?
                                        <Link to={`/${subLink.url.replace("http://", "")}/`} className="menu-g-link">
                                            {subLink.label}
                                        </Link>
                                        :
                                        <div>

                                        </div>
                                )

                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Υπηρεσίες" || parentLabel == "Services") {
            return (
                <ul className={"top80"} >
                    {(props.services && props.services[0].services.edges) && props.services[0].services.edges.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.node.uri.includes("https://")
                                ?
                                <a href={`${subLink.node.uri}/`} className="menu-g-link">
                                    {subLink.node.title}
                                </a>
                                :
                                <Link to={`${subLink.node.uri}`} className="menu-g-link">
                                    {subLink.node.title}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Εξετάσεις" || parentLabel == "Examinations") {
            return (
                <ul className={"top80"} >
                    {(props.projects && props.projects[0].projects.edges) && props.projects[0].projects.edges.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.node.uri.includes("https://")
                                ?
                                <a href={`${subLink.node.uri}/`} className="menu-g-link">
                                    {subLink.node.title}
                                </a>
                                :
                                <Link to={`${subLink.node.uri}`} className="menu-g-link">
                                    {subLink.node.title}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        }
    }


    return (
        <div className={`nav-container mx-auto flex md:block justify-center top-0 w-full ${props.absoluteHeader && "absolute"}`} style={{ zIndex: "9" }} id="menuId">
            <div
                className="preheader hidden md:flex"
                style={{ backgroundColor: "#f9f9f9", borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}
            >
                <div className="preheader hidden md:flex justify-between px-4 container m-auto" style={{ color: "#000" }}>
                    <div className="flex items-center">
                        <div>
                            {props.preHeader.telephoneNumber} / {props.preHeader.mobileNumber}
                        </div>
                        <div className="pl-4">
                            {props.preHeader.supportEmail}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="pre-divider relative pr-4">
                            <span>{props.preHeader.hoursLabel}</span>
                        </div>
                        {/* <div className="flex">
                            <a href="https://www.facebook.com/" target="_blank" rel="noopener"><img src={fbWhite} alt="facebook-icon" style={{ width: "15px" }} /></a>
                            <a href="https://www.instagram.com/" target="_blank" rel="noopener" className="pl-2"><img src={instaWhite} alt="instagram-icon" style={{ width: "15px" }} /></a>
                        </div> */}
                    </div>
                </div>
            </div>

            <Sticky topOffset={500} style={{ width: "100%" }}>
                {({
                    style,
                    // the following are also available but unused in this example
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                }) => (
                    <div className="m-sticky z-20 w-full" style={style}>
                        <div style={{ backgroundColor: "white"}}>
                            <nav className="flex flex-row md:justify-between px-4 container m-auto relative ">
                                <div className="flex flex-row justify-center md:justify-between w-full md:w-auto">
                                    <div id="mainMenu" className="block md:hidden"></div>
                                    <Link to={props.locale === "el" ? '/' : `/${props.locale}/`} className="flex items-center">
                                        {(isSticky || !props.absoluteHeader)
                                            ?
                                            <img className="m-auto py-2" style={{ width: "178px" }} src={logoDark} alt="white-logo" />
                                            :
                                            <img className="m-auto py-2" style={{ width: "178px" }} src={logoLight} alt="white-logo" />
                                        }
                                    </Link>
                                </div>

                                <button
                                    className={!menuState ? 'navbar-toggler navbar-toggler-right collapsed block md:hidden' : 'navbar-toggler navbar-toggler-right block md:hidden'}
                                    type="button"
                                    aria-label="Toggle navigation"
                                    onClick={() => toggleMenu()}
                                >
                                    <span className="icon-bar top-bar"></span>
                                    <span className="icon-bar middle-bar"></span>
                                    <span className="icon-bar bottom-bar"></span>
                                </button>

                                <div className="flex items-center text-xl">
                                    <ul className="mobile-menu nav">
                                        {menu.map((tab, index) => {
                                            return (
                                                <li key={`hMenu-${index}`} className="pr-5" style={{ color: "#000" }}>
                                                    {tab.menuGroup.url === "#"
                                                        ?
                                                        <a href="#" className="w-full">
                                                            <div className="flex items-center justify-between w-full">
                                                                {tab.menuGroup.label}
                                                                {tab.menuGroup.isnested && (
                                                                    <div onClick={(e) => expandMenu(e)} className="block md:hidden ml-8 expand-cross">
                                                                        +
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </a>
                                                        :
                                                        (tab.menuGroup.url.includes("https")
                                                            ?
                                                            <a href={`${tab.menuGroup.url}/`}>
                                                                {tab.menuGroup.label}
                                                            </a>
                                                            :
                                                            <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`} className="w-full">
                                                                <div className="flex items-center justify-between w-full">
                                                                    {tab.menuGroup.label}
                                                                    {tab.menuGroup.isnested && (
                                                                        <div onClick={(e) => expandMenu(e)} className="block md:hidden ml-8 expand-cross">
                                                                            +
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Link>
                                                        )

                                                    }
                                                    {tab.menuGroup.childnodes && tab.menuGroup.childnodes.length > 0 && (
                                                        createSubMenu(tab.menuGroup.childnodes, tab.menuGroup.label, distanceFromTop)
                                                    )}
                                                </li>
                                            )
                                        })}
                                        {(props.availableVersions && props.availableVersions[0] && props.availableVersions.length > 0) && (
                                            <li className="pr-5" style={{ color: "#000" }}>
                                                <Fragment>
                                                    <a href="#" style={{ color: "#000" }}>
                                                        <div className="flex items-center justify-between w-full">
                                                            {props.currentPage.language}
                                                            <div onClick={(e) => expandMenu(e)} className="block md:hidden ml-8 expand-cross">
                                                                +
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className="top80">
                                                        {props.availableVersions.map((item, index) => (
                                                            <Link key={`alt-lang-${index}`} to={item.uri === "arxiki/" ? "/" : `${item.uri}`} className="text-white menu-g-link">
                                                                {item.language.name}
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </Fragment>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                )}
            </Sticky>
        </div>
    )
}

export default MainMenu;