
import React, { useEffect, useState } from "react"
import MainMenu from "./MainMenu"
import FooterMenu from "./FooterMenu"
import { Helmet } from "react-helmet"
import GoTop from './Global/GoTop'

import { StickyContainer } from 'react-sticky';

const Layout = ({ children, headerMenu, footerMenu, recentPosts, currentPage, availableVersions, metaData, services, projects, preHeader, absoluteHeader }) => {
    const [cssInit, setCssInit] = useState(false)
    useEffect(() => {



        // setInterval(() => {
        //     if (!cssInit) {
        //         let acc = document.getElementById("cookiescript_accept");

        //         if (acc) {
        //             acc.style.backgroundColor = "green";
        //             setCssInit(true)
        //         }

        //         let acc2 = document.getElementById("cookiescript_declaration");

        //         if (acc2) {
        //             acc2.style.backgroundColor = "green";
        //         }

        //         let acc3 = document.getElementsByClassName("cookiescript_category_performance");

        //         if (acc3[0]) {
        //             acc3[0].style.backgroundColor = "green";
        //         }
        //     }
        // }, 1000)



    }, [])

    return (
        <StickyContainer>
            <div>
                {(metaData && availableVersions[0]) && (
                    <Helmet>

                        <html lang={metaData.locale} />
                        <title>{metaData.title}</title>
                        <meta name="description" content={metaData.description} />
                        <link rel="canonical" href={metaData.canonical} />
                        <link rel="alternate" hreflang={metaData.locale} href={metaData.canonical} />
                        {/* { availableVersions.map((item, i) => (
                        <link key={`hrefl-${i}`} rel="alternate" hreflang={item.language.slug} href={"https://abt.gr" + (!item.uri.includes("arxiki") ? item.uri : "")} />
                    ))} */}
                        <link rel="alternate" hreflang={"x-default"} href={metaData.canonical.includes("/en/") ? "https://abt.gr" + availableVersions[0].uri : metaData.canonical} />
                        <meta
                            name="viewport"
                            content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                        />

                        <meta property="og:title" content={metaData.fbTitle} />
                        <meta property="og:description" content={metaData.fbDescription} />
                        <meta property="og:url" content={metaData.canonical} />
                        <meta property="og:type" content={metaData.fbType} />
                        <meta property="og:locale" content={metaData.locale} />
                        <meta property="og:image" content={metaData.image} />
                        <meta property="og:image:width" content="598" />
                        <meta property="og:image:height" content="399" />

                        {/* <script type="text/javascript" charset="UTF-8" src="//cdn.cookie-script.com/s/529c9062530666cb3dff8dea055b105d.js"></script> */}

                        {/* <script dangerouslySetInnerHTML={{
                    __html: `(function(){var s = document.createElement("script");s.setAttribute("data-account","UPnYfxjYue");s.setAttribute("src","https://cdn.userway.org/widget.js");document.body.appendChild(s);})();`}}>
                    </script> */}

                        <script src="https://cdn.userway.org/widget.js" data-account="UPnYfxjYue" />

                    </Helmet>
                )}



                {headerMenu && (
                    <div className="w-full">
                        <MainMenu headerMenu={headerMenu} currentPage={currentPage} absoluteHeader={absoluteHeader} availableVersions={availableVersions} services={services} projects={projects} locale={metaData.locale} preHeader={preHeader} />
                    </div>
                )}
                {children}
                {footerMenu && (
                    <FooterMenu footerMenu={footerMenu} currentPage={currentPage} recentPosts={recentPosts} contact={preHeader} />
                )}

                <GoTop scrollStepInPx="100" delayInMs="10.50" />

            </div>
        </StickyContainer>
    )
}
export default Layout

            // export const query = graphql`
//   fragment SeoFragment on WPGraphQL_SEO {
//     whoWeAre {
//         title
//         content
//     }
//   }
// `
