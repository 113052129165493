import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import logoWhite from "../images/logo-abt-white.png";

import "./FooterMenu.css";

function FooterMenu(props) {

    // console.log(props)
    const menu = props.footerMenu ? props.footerMenu.menuAcf.menu : [];
    const extra = props.footerMenu ? props.footerMenu.footerExtraAcf : "";

    return (
        <div>
            <div className="footer-container mx-auto px-5">
                <nav className=" container m-auto flex flex-wrap lg:justify-around pt-16 pb-8">
                    <div className="flex flex-row flex-wrap justify-between pb-8 lg:pb-0 w-full md:w-1/3 px-4 md:px-12 fbr" >
                        <div>
                            <img className="w-64" src={logoWhite} alt="white-logo" />
                        </div>
                        <div className="w-full flex justify-center">
                            <a href="#" className="text-white" style={{fontSize: "16px",fontWeight: "200",lineHeight: "2", pointerEvents: "none"}}>
                                {extra.description}
                            </a>
                        </div>
                    </div>
                    <div className="items-center w-full md:w-1/3 px-4 md:px-12 pb-8 pt-4 md:pt-0 fbr" >
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h2 className="w-full f-titles">{extra.linksLabel}</h2>
                            </div>
                            <ul className="footer-links">
                                {menu.map((tab, index) => (
                                    <li key={`hMenu-${index}`} className="md:pr-2 xl:pr-8 ">
                                        <a href={tab.menuGroup.url === "/" ? "/" : "/" + tab.menuGroup.url + "/"}>
                                            {tab.menuGroup.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="items-center w-full md:w-1/3 px-4 md:px-12 pb-8">

                        <div className="w-full">
                            <h2 className="w-full f-titles">{extra.postsLabel}</h2>
                        </div>
                        <div className="text-white">

                            <div className="pb-4">
                                <a href="#hours" style={{ color: "#d7d7d7", fontSize: "16px", fontWeight: "200" }}>{props.contact.hoursLabel}</a>
                            </div>
                            <div className="pb-4">
                                <a href={`mailto:${props.contact.supportEmail}`}>
                                    {props.contact.supportEmail}
                                </a>
                            </div>
                            <div className="pb-4">
                                <a href={`tel:${props.contact.telephoneNumber.replace(/ /g, "")}`}>
                                    {props.contact.telephoneNumber}
                                </a>
                            </div>
                            {/* <div className="pb-4">
                                <a href={`tel:${props.contact.mobileNumber}`}>
                                    {props.contact.mobileNumber}
                                </a>
                            </div> */}
                        </div>
                    </div>
                </nav>
            </div>
            <div className="w-full" style={{ backgroundColor: "#242424" }}>
                <div className="py-6 w-full flex flex-wrap justify-center container m-auto ">
                    <div className="px-4 md:px-12 text-center md:text-left">
                        <div style={{ color: "#ffffff" }} className="inner-p-w" dangerouslySetInnerHTML={{ __html: extra.copyrights }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterMenu;

export const query = graphql`fragment RecentPostsFragment on WPGraphQL_RootQueryToProjectConnection {
  edges {
    node {
      uri
      slug
      title
      date
      featuredImage {
        node {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 100, quality: 100, placeholder: BLURRED, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`